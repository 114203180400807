<template>
  <div class="app-container speciality">
    <h3 class="title">{{CategoryName}}</h3>
    <div class="mainbox" v-for="(item,index) in pagelist" :key="index">
      <div class="titlebox">
        <h4 @click="godetail(item)">{{item.ProfessionName}}</h4>
        <i @click="godetail(item)" v-if="item.HasVideo" class="el-icon-caret-right iconbox"></i>
      </div>
      <div class="content">{{item.ProfessionDesc}}</div>
    </div>
    <div class="el-pagination" v-if="total>PageSize">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="PageSize"
        :current-page="PageIndex"
        @current-change="PageIndexChange"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import API from '@/api/config'
// import { ProfessionList } from "@/api/career/profession";
export default {
  data() {
    return {
      CategoryId: "",
      CategoryName: "",
      pagelist: null,
      PageIndex: 1,
      PageSize: 10,
      total: 0
    };
  },
  created() {
    this.init();
  },
  watch: {
    $route: function(to, from) {
      //执行数据更新查询
      this.init();
    }
  },
  methods: {
    init() {
      if (this.$route.query.CategoryId) {
        this.CategoryId = this.$route.query.CategoryId;
        this.CategoryName = this.$route.query.CategoryName;
        this.getdata();
      }
    },
    PageIndexChange(val) {
      this.PageIndex = val;
      this.getdata();
    },
    getdata() {
      API.Profession.ProfessionList({
        CategoryId: this.CategoryId,
        PageIndex: this.PageIndex,
        PageSize: this.PageSize
      }).then(res => {
        this.pagelist = res.data.Data;
        this.total = res.data.Total;
      });
    },
    godetail(val) {
      this.$router.push({
        path: "/profession-detail",
        query: { ID: val.ID }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.speciality {
  padding-bottom: 2rem;
  width: 66%;
  .title {
    color: rgba(var(--themecolor),1);
  }
  h4 {
    cursor: pointer;
  }
  h4:hover{
    color: rgba(var(--themecolor),1);
  }
}
.content {
  white-space: pre-wrap;
  line-height: 1.5;
  color: #999;
}
.el-pagination {
  text-align: center;
  padding: 20px 0;
}
.mainbox {
  padding: 20px;
  &:hover {
    background: #f9f9f9;
  }
 .titlebox {
  display: flex;
  align-items: center;
  height: 60px;
  line-height: 1.5;
  font-size: 20px;
  margin-bottom: 10px;
 
  .iconbox {
    border: 1px solid rgba(var(--themecolor),0.55);
    color: rgba(var(--themecolor),0.55);
    border-radius: 4px;
    margin-left: 6px;
  }
 
}
}

</style>